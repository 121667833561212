html {
  background-color: orange;
}

#root {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-items: center;
  align-items: center;
}

body {
  height: 100vh;
  background-image: url("./images/background.jpg");
  background-size: cover;
  background-position: center;
}

h1 {
  background-image: url("./images/categorie.png");
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  padding: 16px 48px;
  max-width: 200px;
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 100vh;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  gap: 30px;
  height: 90%;
  /* justify-items: center; */
  overflow-y: hidden;
}

.home-wrapper {
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.item-holder {
  background-image: url("./images/categorie.png");
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 10rem;
  min-height: 6rem;
  font-size: 1.2rem;
  text-align: center;
  text-decoration: none;
  color: black;
  padding: 16px 24px;
  align-content: center;
}

.search {
  font-size: 2rem;
  width: 400px;
  height: 80px;
  padding: 15px;
  border-radius: 25px;
  margin-bottom: 40px;
}

.search-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-items: center;
  align-items: center;
}

.detail-item {
  background-image: url("./images/categorie.png");
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 110px;
  border-radius: 15px;
  font-size: 1.2rem;
  text-align: center;
  text-decoration: none;
  color: black;
  /* padding: 48px 96px; */
  align-content: center;
  height: 50%;
  width: 60%;
  margin: auto;
}

.terug {
  background-image: url("./images/categorie.png");
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  /* border-radius: 15px; */
  padding: 10px 32px;
  font-size: 1.5rem;
  font-weight: 500;
  color: black;
  text-decoration: none;
}
